@use 'styles/_variables.scss' as *;
.wrapper {
  max-height: 100%;
  padding: 1.5 * $global-padding;
  box-sizing: content-box;

  &:last-child {
    flex-grow: 1;
    min-width: 150px;
  }

  @media (min-width: $l-screen) {
    padding: $global-padding 0;
  }

  &:has(*[class^="amber-icon-"]) {
    gap: $global-margin;
  }
  &:has(*[class^="LinkCollection_rootLink__"]) {
    .linksWrapper {
      margin-top: 0.5 * $global-margin;
    }
  }

  .title {
    padding-bottom: 0.5 * $global-padding !important;
    margin-bottom: 0.75 * $global-margin;
    color: $almost-black-60;
    font-family: $brand-font-family;
    font-size: 12px;
    font-weight: 700;
    line-height: 115%;
    letter-spacing: 1px;
    text-transform: uppercase;
    border-bottom: 1px solid $pale-gray-4;
    white-space: nowrap;
    min-width: min-content;

    @media (min-width: $l-screen) {
      margin-inline: $global-margin;
    }
  }

  .linksWrapper {
    display: flex;
    gap: 0.5 * $global-margin;
    flex-wrap: wrap;

    &.mobileSubmenu {
      a {
        padding: 0.5 * $global-padding 0 !important;
      }
    }

    a {
      @media (min-width: $l-screen) {
        padding: 0.75 * $global-padding $global-padding !important;
      }
    }

    &:has([class^="amber-icon-"]) {
      gap: $global-margin;

      a {
        @media (min-width: $l-screen) {
          padding: $global-padding !important;
        }
      }
    }

    .label {
      color: $indigo;
      cursor: default;
    }
  }
}

.sublink {
  display: flex;
  flex-direction: row;
  gap: 0.24 * $global-margin;
  padding: 0.5 * $global-padding 0;
  width: 100%;
  cursor: pointer;

  @media (min-width: $l-screen) {
    width: auto;
  }

  & > a:first-child {
    width: auto;
  }

  .sublinkIcon {
    transform: rotate(-90deg);
    color: $dark-gray-4;
    cursor: pointer;
    @media (min-width: $l-screen) {
      display: none;
    }
  }
}

.rootLink {
  display: flex;
  flex-direction: row;
  gap: 0.25 * $global-margin;
  margin: 0.5 * $global-padding 0;

  .rootLinkIcon {
    transform: rotate(90deg);
    cursor: pointer;
  }
}
