@use 'styles/_variables.scss' as *;
.wrapper {
  display: flex !important;
  flex-direction: column;
  gap: 0.75 * $global-margin;
  padding: 0 !important;
  border-radius: 16px;
  transition: 0.3s;
  text-decoration: none !important;
  width: 100%;
  cursor: pointer;

  &.withoutLink {
    width: fit-content;
  }

  @media (min-width: $l-screen) {
    max-width: 222px;
    padding: $global-padding !important;
  }

  @media (min-width: 1062px) {
    max-width: 256px;
  }

  &:has(.logo) {
    flex-direction: row;
    width: auto;

    @media (min-width: $l-screen) {
      flex-direction: column;
      gap: 0.5 * $global-margin;
    }
  }

  @media (min-width: $l-screen) {
    &:hover {
      background: $pale-gray-2;
    }
  }

  .header {
    display: flex !important;
    flex-direction: row;
    gap: 0.5 * $global-margin;
    width: 100%;
    align-items: center;
    padding: 0 !important;

    svg {
      display: block;
      width: 24px;
      height: 24px;

      & * {
        fill: $dark-gray-4;
      }
    }

    div {
      font-size: 0.875 * $base-font-size;
      font-weight: 700;
      line-height: 125%;
      color: $almost-black;

      @media (min-width: $l-screen) {
        color: $dark-gray-4;
      }
    }
  }

  .description {
    display: none;

    @media (min-width: $l-screen) {
      display: block;
    }

    padding: 0;
    font-size: 0.875 * $base-font-size;
    font-weight: 300;
    line-height: 125%;
    color: $almost-black-60;
  }

  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 143px;
    height: 88px;
    border-radius: 12px;

    svg {
      width: 50px;
      height: 50px;

      & > * {
        fill: $white;
      }
    }

    @media (max-width: $l-screen) {
      background-color: transparent !important;
      width: unset;
      height: unset;

      svg {
        width: 20px;
        height: 20px;

        & * {
          fill: $dark-gray-4;
        }
      }
    }
  }

  .logoName {
    font-size: 0.875 * $base-font-size;
    font-weight: 500;
    line-height: 125%;
    color: $almost-black;
  }
}
